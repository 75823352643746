import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
} from './BaseApi';

/*************************   Company Setting    ****************************/
async function updateLogo(logoFile, complete) {
  let reader = new FileReader();
  reader.readAsDataURL(logoFile);
  reader.onload = async () => {
    const company = appdata.getItem('company');
    const url = `${API_ROOT}/companysettings/${company.id}/upload_logo`;
    let res = await dispatchPostJSONAsync(url, {
      logo_file: reader.result.split(',')[1],
    });
    complete(res);
  };
}

async function updateUserLayoutColor(color) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/update_layout_color`;
  return await dispatchPostJSONAsync(url, { color });
}

async function updateUserTextColor(color) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/update_text_color`;
  return await dispatchPostJSONAsync(url, { color });
}

async function updateUserLayoutType(layout_type) {
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/companysettings/${user.id}/update_layout_type`;
  return await dispatchPostJSONAsync(url, { layout_type });
}

async function updateUserDarkMode(dark_mode) {
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/companysettings/${user.id}/update_dark_mode`;
  return await dispatchPostJSONAsync(url, { dark_mode });
}

async function getUserSettings(payload) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/get_user_settings`;
  return await dispatchPostJSONAsync(url, {
    ...payload,
  });
}

async function updateUserSettings(payload) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/update_user_setting`;
  return await dispatchPostJSONAsync(url, {
    ...payload,
  });
}

async function getAllCompanySettings() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}`;
  return await dispatchGetJSONAsync(url);
}

async function addCompanySetting(company_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/add`;
  return await dispatchPostJSONAsync(url, { ...company_data });
}

async function deleteCompanySetting(company_setting_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    company_setting_id,
  });
}

async function updateCompanySettingsData(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    ...updated_data,
  });
}

async function getAllOrganisationSettings() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/organisation/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function addOrganisationSetting(organisation_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/organisation/add`;
  return await dispatchPostJSONAsync(url, { ...organisation_data });
}

async function deleteOrganisationSetting(organisation_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/organisation/delete`;
  return await dispatchPostJSONAsync(url, {
    organisation_id,
  });
}

async function updateOrganisationSetting(organisation_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/organisation/update`;
  return await dispatchPostJSONAsync(url, { ...organisation_data });
}

async function getAllCompanyApiKeys(company_org_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/apikeys/${company_org_id}`;
  return await dispatchGetJSONAsync(url);
}

async function updateCompanyApiKey(
  id,
  apitype,
  companyfileid,
  username,
  password,
  ApiAccounts,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/apikeys/update`;
  return await dispatchPostJSONAsync(url, {
    id,
    apitype,
    companyfileid,
    username,
    password,
    ApiAccounts,
  });
}

async function getIntegrationLogs(company_org_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/get_integration/${company_org_id}`; 
  return await dispatchGetJSONAsync(url);
}

async function getIntegrationLogsDashboard(company_org_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/get_integration_dashboard/${company_org_id}`; 
  return await dispatchGetJSONAsync(url);
}

async function updateIntegrationLog(update_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/update_integration`;
  return await dispatchPostJSONAsync(url, update_data);
}

async function deleteIntegrationLog(integration_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/delete_integration`;
  return await dispatchPostJSONAsync(url, { integration_id });
}

async function requestIntegrationLog(request_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datamanagement/${company.id}/reintegration`;
  return await dispatchPostJSONAsync(url, request_data);
}

/*************************   System Setting    ****************************/
async function getSystemSetting(company_org_id = null) {
  const company = appdata.getItem('company');
  if (company) 
    {
      const url = `${API_ROOT}/systemsettings/${company.id}/get`;
      return await dispatchPostJSONAsync(url, { company_org_id });
    }
  else { return []}
}

async function updateSystemSetting(system_setting, company_org_id = null) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    ...system_setting,
    company_org_id,
  });
}

async function getInviteEmailSetting(company_org_id = null) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/get_invite_email`;
  return await dispatchPostJSONAsync(url, { company_org_id });
}

async function updateInviteEmailSetting(
  company_org_id = null,
  design,
  html,
  subject,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/update_invite_email`;
  return await dispatchPostJSONAsync(url, {
    company_org_id,
    design,
    html,
    subject,
  });
}

async function formatInviteEmailSetting(company_org_id = null) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/format_invite_email`;
  return await dispatchPostJSONAsync(url, { company_org_id });
}

async function getAllCompanyTaxExpenses() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/get_all_taxes_expenses`;
  return await dispatchGetJSONAsync(url);
}

async function checkProjectApi() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/check_project`;
  return await dispatchGetJSONAsync(url);
}

function fetchAuthRetry(url, options = {}, attempts = 3) {
  return fetch(url, options).then((response) => {
    if (response.status !== 401 || attempts <= 0) return response;
    return fetchAuthRetry(url, options, attempts - 1);
  });
}

function checkRelatedTaskApi() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/check_related`;
  fetchAuthRetry(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function updateTimeFormatApi() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/update_time_format`;
  fetchAuthRetry(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

function checkTaskAPI() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/systemsettings/${company.id}/check_task_id`;
  fetchAuthRetry(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

/*************************   List Setting    ****************************/
async function getListData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/${company.id}/list/get`;
  return await dispatchGetJSONAsync(url);
}

async function addListData(add_list_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/${company.id}/list/add`;
  return await dispatchPostJSONAsync(url, add_list_data);
}

async function populateListData(listItems) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/${company.id}/list/populate`;
  return await dispatchPostJSONAsync(url, listItems);
}

async function deleteListData(list_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/${company.id}/list/delete`;
  return await dispatchPostJSONAsync(url, {
    list_id,
  });
}

async function updateListData(updated_list_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/${company.id}/list/update`;
  return await dispatchPostJSONAsync(url, updated_list_data);
}

async function getRoles() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}`;
  return await dispatchGetJSONAsync(url);
}

async function getUserRole() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/settings/roles/${company.id}/user_role/${user.id}`;
  return await dispatchGetJSONAsync(url);
}

async function getCompanyPeoples() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/company_people`;
  return await dispatchGetJSONAsync(url);
}

async function getConnectPeople() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/connect_people`;
  return await dispatchGetJSONAsync(url);
}

async function getAR() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/get_AR`;
  return await dispatchGetJSONAsync(url);
}

async function update_ARNotes(invoice_id, notes) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/update_AR_notes`;
  return await dispatchPostJSONAsync(url, 
    invoice_id,
    notes
  );
}

async function updateCompanyPeople(userId, people_key) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/company_people/update`;
  return await dispatchPostJSONAsync(url, {
    userId,
    people_key,
  });
}

async function updateCompanyOrgId(userId, company_key) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/companyorg/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    userId,
    company_key,
  });
}

async function addRole(role_name) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/add`;
  return await dispatchPostJSONAsync(url, {
    role_name,
  });
}

async function deleteRole(role_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    role_id,
  });
}

async function updateRole(role_id, role_name, role_accesses) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    role_id,
    role_name,
    role_accesses,
  });
}

async function addUsersToRole(role_id, users) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/add_users`;
  const user_ids = users.map((user) => user.id);
  return await dispatchPostJSONAsync(url, {
    role_id,
    user_ids,
  });
}

async function deleteUsersFromRole(role_id, users) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/roles/${company.id}/delete_users`;
  const user_ids = users.map((user) => user.id);
  return await dispatchPostJSONAsync(url, {
    role_id,
    user_ids,
  });
}

/*************************   Rate Resource Setting    ****************************/
async function getRateResourceData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/rateresource/${company.id}/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function addRateResource(add_resource) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/rateresource/${company.id}/add`;
  return await dispatchPostJSONAsync(url, add_resource);
}

async function editRateResource(edit_resource) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/rateresource/${company.id}/edit`;
  return await dispatchPostJSONAsync(url, edit_resource);
}

async function deleteRateResource(ids) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/rateresource/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, ids);
}

async function editRateName(edit_rate) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settings/rateresource/${company.id}/rate/edit`;
  return await dispatchPostJSONAsync(url, edit_rate);
}

/*************************   Template Management Setting    ****************************/
async function getAllTemplates() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getTemplate(template_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/get/${template_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addTemplate(add_template_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/add`;
  return await dispatchPostJSONAsync(url, { ...add_template_data });
}

async function deleteTemplate(template_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    template_id,
  });
}

async function updateTemplate(update_template_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/update`;
  return await dispatchPostJSONAsync(url, { ...update_template_data });
}

async function updateTemplateContent(
  template_id,
  template_setting,
  template_property,
  currency_name,
  currency_symbol,
  thousand_format,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/update_content`;
  return await dispatchPostJSONAsync(url, {
    template_id,
    template_setting,
    template_property,
    currency_name,
    currency_symbol,
    thousand_format,
  });
}

async function uploadTemplate(template_id, docFile, complete) {
  let reader = new FileReader();
  reader.readAsDataURL(docFile);
  reader.onload = async () => {
    const company = appdata.getItem('company');
    const url = `${API_ROOT}/templates/${company.id}/upload_template`;
    let res = await dispatchPostJSONAsync(url, {
      template_id,
      template_doc_file: reader.result.split(',')[1],
    });
    complete(res);
  };
}

async function createProjectDoc(
  project_id,
  project_type,
  template_id,
  project_number,
  project_name,
  invoice_number,
  people_name,
  companies_name,
  address_1,
  address_2,
  city,
  state,
  country,
  site_address1,
  site_address2,
  site_city,
  site_state,
  site_country,
  project_notes,
  tasks,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/project`;

  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    template_id,
    project_number,
    project_name,
    invoice_number,
    people_name,
    companies_name,
    address_1,
    address_2,
    city,
    state,
    country,
    site_address1,
    site_address2,
    site_city,
    site_state,
    site_country,
    project_notes,
    tasks,
  });
}

async function createInvoiceDoc(
  project_id,
  template_id,
  template_data,
  grid_columns,
  invoice_id,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/invoice`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    template_id,
    template_data,
    grid_columns,
    invoice_id,
  });
}

/*************************   Project Template Settings   ****************************/
async function getProjectTemplate() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/projecttemplate/get_project_template/${company.id}/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function updateProjectTemplate(data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/projecttemplate/update_project_template/${company.id}/post_all`;
  return await dispatchPostJSONAsync(url, {
    ...data,
  });
}

async function addProjectTemplate(data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/projecttemplate/add_template/${company.id}/add_new_template`;
  return await dispatchPostJSONAsync(url, {
    ...data,
  });
}

async function deleteProjectTemplate(data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/projecttemplate//delete_template/${company.id}/delete_template`;
  return await dispatchPostJSONAsync(url, {
    ...data,
  });
}

/*************************   Helper Settings    ****************************/

async function getHelperData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/helper/get_helper_settings/${company.id}/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function updateHelperData(helper_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/helper/get_helper_settings/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    ...helper_data,
  });
}

async function updateHelperDefaultData(helper_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/helper/get_helper_settings/${company.id}/update_default`;
  return await dispatchPostJSONAsync(url, {
    ...helper_data,
  });
}

async function updateHelperStepsData(helper_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/helper/get_helper_settings/${company.id}/update_steps`;
  return await dispatchPostJSONAsync(url, {
    ...helper_data,
  });
}

async function updateHelperDescriptionData(helper_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/helper/get_helper_settings/${company.id}/update_description`;
  return await dispatchPostJSONAsync(url, {
    ...helper_data,
  });
}

async function setDefaultHelperData(helper_data){
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/helper/get_helper_settings/${company.id}/set_description`;
  return await dispatchPostJSONAsync(url, {
    ...helper_data,
  });
}

/*************************   Notification Settings    ****************************/

async function addNotificationLogs(payload) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/add_notification_logs`; 
  return await dispatchPostJSONAsync(url, payload);
}

async function updateNotificationLogs(id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/companysettings/${company.id}/update_notification_logs`;
  return await dispatchPostJSONAsync(url, id);
}


/*************************   Invoice Email Settings    ****************************/

async function createCreditDoc(invoice_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/credit/${invoice_id}`;
  return await dispatchGetJSONAsync(url);
}

async function creditCreateInvoiceDoc(
  project_id,
  template_id,
  template_data,
  grid_columns,
  invoice_id,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/credit`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    template_id,
    template_data,
    grid_columns,
    invoice_id,  });
  }
  
async function getEmailTemplate() {
  const company = appdata.getItem('company');
  const company_org_id = appdata.getItem('company_org_id');
  const url = `${API_ROOT}/settings/emailtemplate/${company.id}/get`;
  return await dispatchPostJSONAsync(url, { company_org_id });
}

async function updateEmailTemplate(email_type, design, html, subject, enable, percentage, notification, notification_layout) {
  const company = appdata.getItem('company');
  const company_org_id = appdata.getItem('company_org_id');
  const url = `${API_ROOT}/settings/emailtemplate/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    email_type,
    company_org_id,
    design,
    html,
    subject,
    enable, 
    percentage, 
    notification, 
    notification_layout
  });
}

const SettingApi = {
  updateLogo,
  updateUserLayoutColor,
  updateUserTextColor,
  updateUserLayoutType,
  updateUserDarkMode,
  getAllCompanySettings,
  addCompanySetting,
  deleteCompanySetting,
  updateCompanySettingsData,
  getAllOrganisationSettings,
  addOrganisationSetting,
  deleteOrganisationSetting,
  updateOrganisationSetting,
  getAllCompanyApiKeys,
  updateCompanyApiKey,

  getSystemSetting,
  updateSystemSetting,
  getInviteEmailSetting,
  updateInviteEmailSetting,
  formatInviteEmailSetting,
  getAllCompanyTaxExpenses,
  checkProjectApi,
  checkRelatedTaskApi,
  updateTimeFormatApi,
  checkTaskAPI,

  getIntegrationLogs,
  getIntegrationLogsDashboard,
  updateIntegrationLog,
  deleteIntegrationLog,
  requestIntegrationLog,

  getListData,
  addListData,
  populateListData,
  deleteListData,
  updateListData,
  getRoles,
  updateCompanyOrgId,
  getUserRole,
  getCompanyPeoples,
  getConnectPeople,
  updateCompanyPeople,
  addRole,
  deleteRole,
  updateRole,
  addUsersToRole,
  deleteUsersFromRole,

  getRateResourceData,
  addRateResource,
  editRateResource,
  deleteRateResource,
  editRateName,

  getAllTemplates,
  getTemplate,
  addTemplate,
  deleteTemplate,
  updateTemplate,
  updateTemplateContent,
  uploadTemplate,
  createProjectDoc,
  createInvoiceDoc,

  getHelperData,
  updateHelperData,
  updateHelperStepsData, 
  updateHelperDescriptionData, 
  updateHelperDefaultData,
  setDefaultHelperData, 
  createCreditDoc,

  getProjectTemplate,
  updateProjectTemplate,
  addProjectTemplate,
  deleteProjectTemplate,
  creditCreateInvoiceDoc,

  getUserSettings,
  updateUserSettings, 
  getAR,
  update_ARNotes,
  getEmailTemplate,
  updateEmailTemplate,

  addNotificationLogs,
  updateNotificationLogs
};

export default SettingApi;
