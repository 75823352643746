import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
  dispatchFilePostJSONAsync,
  dispatchPostFormAsync,
} from './BaseApi';

async function getPeople(people_type = 'All') {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}`;
  let people = await dispatchPostJSONAsync(url, { people_type });
  return people;
}

async function getPeopleData(DimPeopleKey) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/get_people_data`;
  let people = await dispatchPostJSONAsync(url, { DimPeopleKey });
  return people;
}

async function getPeoplePhoto(image_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/get_photo`;
  return await dispatchPostJSONAsync(url, { image_id });
}

async function addPeople(people_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/add`;
  return await dispatchPostJSONAsync(url, people_data);
}

async function deletePeople(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    people_id,
  });
}

async function updatePeople(people_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/update`;
  return await dispatchPostJSONAsync(url, people_data);
}

async function getPeopleHistory(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/history`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function getPeopleGeneral(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/general/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleGeneral(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/general/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deletePeopleGeneral(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/general/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function deletePeopleGenerals(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/general/delete_all`;
  return await dispatchPostJSONAsync(url, {
    people_id,
  });
}

async function updatePeopleGeneral(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/general/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function addCompanyFromPeople(client_detail) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/general/add_client`;
  return await dispatchPostJSONAsync(url, client_detail);
}

async function getPeopleEducation(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/education/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleEducation(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/education/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleEducation(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/education/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleEducation(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/education/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getPeopleSkills(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/skills/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleSkills(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/skills/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleSkills(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/skills/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleSkills(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/skills/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getPeopleKpis(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/kpis/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleKPIs(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/kpis/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleKPIs(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/kpis/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleKPIs(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/kpis/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getPeopleReviews(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/reviews/${people_id}`;
  return await dispatchGetJSONAsync(url, { people_id });
}

async function addEmptyRowToPeopleReviews(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/reviews/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleReviews(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/reviews/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleReviews(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/reviews/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getPeopleTraining(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/training/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleTraining(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/training/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleTraining(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/training/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleTraining(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/training/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getPeopleContactPoints(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/contact_points/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleContactPoints(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/contact_points/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleContactPoints(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/contact_points/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleContactPoints(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/contact_points/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getPeopleSalary(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/salary/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleSalary(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/salary/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleSalary(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/salary/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleSalary(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/salary/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getAllRates() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/rates/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getPeopleRates(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/rates/${people_id}`;
  return await dispatchGetJSONAsync(url, { people_id });
}

async function addEmptyRowToPeopleRates(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/rates/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleRates(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/rates/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleRates(people_id, updated_data, approve) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/rates/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
    approve,
  });
}

async function reCalcRates(people_id, row, start_date, end_date) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/rates/calc_all`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    row,
    start_date,
    end_date,
  });
}

async function reCalcCompanyRates(start_date, end_date) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/rates/calc_all_company`;
  return await dispatchPostJSONAsync(url, {
    start_date,
    end_date,
  });
}

async function getPeopleLeave(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/leave/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleLeave(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/leave/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleLeave(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/leave/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleLeave(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/leave/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getAllPeopleWorkPatterns() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/work_pattern/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getPeopleWorkPattern(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/work_pattern/get/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToPeopleWorkPattern(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/work_pattern/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function updatePeopleWorkPattern(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/work_pattern/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getAllPeopleHolidays() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/holiday/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getPeopleHolidays(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/holiday/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function getPersonHolidays(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/holiday/get_person_holiday`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function addEmptyRowToPeopleHoliday(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/holiday/add`;
  return await dispatchPostJSONAsync(url, { people_id });
}

async function deleteRowFromPeopleHoliday(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/holiday/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updatePeopleHoliday(people_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/holiday/update`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    updated_data,
  });
}

async function getDimCompanies() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/get_company`;
  return await dispatchGetJSONAsync(url);
}

async function getDimOrganisations() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/get_organisation`;
  return await dispatchGetJSONAsync(url);
}

async function uploadPeoplePhoto(people_id, photoFile) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/upload_photo`;
  let data = new FormData();
  data.append('people_id', people_id);
  data.append('photo_file', photoFile);
  data.append('file_name', photoFile.name);
  return await dispatchPostFormAsync(url, data);
}

async function uploadPeopleFieldPhoto(photoFile, complete) {
  let reader = new FileReader();
  reader.readAsDataURL(photoFile);
  reader.onload = async () => {
    const company = appdata.getItem('company');
    const user = appdata.getItem('user');
    const url = `${API_ROOT}/datapeople/${company.id}/${user.id}/upload_field_photo`;
    let res = await dispatchPostJSONAsync(url, {
      user_id: user.id,
      photo_file: reader.result.split(',')[1],
    });
    complete(res.photo_url);
  };
}

async function updatePeopleInOut(in_out_data) {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/datapeople/${company.id}/in_out/${user.id}`;
  return await dispatchPostJSONAsync(url, {
    in_out_data,
  });
}

async function getPeopleWithEmail(Email) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/get_people/with_email`;
  return await dispatchPostJSONAsync(url, {
    Email,
  });
}

async function getPeopleInoutData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/inout`;
  return await dispatchGetJSONAsync(url);
}

async function importPeopleInoutData(import_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/inout/import`;
  return await dispatchPostJSONAsync(url, { import_data });
}

async function updatePeopleInoutData(update_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datapeople/${company.id}/inout/update`;
  return await dispatchPostJSONAsync(url, update_data);
}

const DataPeopleApi = {
  getPeople,
  getPeopleData,
  getPeoplePhoto,
  addPeople,
  deletePeople,
  updatePeople,

  getPeopleHistory,

  getPeopleGeneral,
  addEmptyRowToPeopleGeneral,
  deletePeopleGeneral,
  deletePeopleGenerals,
  updatePeopleGeneral,
  addCompanyFromPeople,

  getPeopleEducation,
  addEmptyRowToPeopleEducation,
  deleteRowFromPeopleEducation,
  updatePeopleEducation,

  getPeopleSkills,
  addEmptyRowToPeopleSkills,
  deleteRowFromPeopleSkills,
  updatePeopleSkills,

  getPeopleKpis,
  addEmptyRowToPeopleKPIs,
  deleteRowFromPeopleKPIs,
  updatePeopleKPIs,

  getPeopleReviews,
  addEmptyRowToPeopleReviews,
  deleteRowFromPeopleReviews,
  updatePeopleReviews,

  getPeopleTraining,
  addEmptyRowToPeopleTraining,
  deleteRowFromPeopleTraining,
  updatePeopleTraining,

  getPeopleContactPoints,
  addEmptyRowToPeopleContactPoints,
  deleteRowFromPeopleContactPoints,
  updatePeopleContactPoints,

  getPeopleSalary,
  addEmptyRowToPeopleSalary,
  deleteRowFromPeopleSalary,
  updatePeopleSalary,

  getAllRates,
  getPeopleRates,
  addEmptyRowToPeopleRates,
  deleteRowFromPeopleRates,
  updatePeopleRates,
  reCalcRates,
  reCalcCompanyRates,

  getPeopleLeave,
  addEmptyRowToPeopleLeave,
  deleteRowFromPeopleLeave,
  updatePeopleLeave,

  getAllPeopleWorkPatterns,
  getPeopleWorkPattern,
  addEmptyRowToPeopleWorkPattern,
  updatePeopleWorkPattern,

  getAllPeopleHolidays,
  getPeopleHolidays,
  addEmptyRowToPeopleHoliday,
  deleteRowFromPeopleHoliday,
  updatePeopleHoliday,
  getPersonHolidays,

  getDimCompanies,
  getDimOrganisations,

  uploadPeoplePhoto,
  uploadPeopleFieldPhoto,
  updatePeopleInOut,
  getPeopleWithEmail,

  getPeopleInoutData,
  importPeopleInoutData,
  updatePeopleInoutData,
};

export default DataPeopleApi;
